import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {NAVBAR_LOGO_CLICKED, trackEvent} from '../../../lib/analytics';

export default function Logo({children, className}) {
  const router = useRouter();
  const [href, setHref] = useState('https://placeit.net');

  const logoClickHandler = () => {
    trackEvent(NAVBAR_LOGO_CLICKED);
    router.push(href);
  };

  useEffect(() => {
    setHref(window.location.origin);
  }, []);

  return (
    <a href={href} className={className} onClick={logoClickHandler}>
      {children}
    </a>
  );
}

Logo.defaultProps = {
  className: '',
  children: null,
};
Logo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export const LogoPlasmicData = {
  name: 'Nav.Logo',
  props: {
    children: {
      type: 'slot',
      defaultValue: {
        type: 'img',
      },
    },
  },
  defaultStyles: {height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'},
  importPath: './components/NavBar/MenuBar/Logo',
  isDefaultExport: true,
};
